<script lang="ts">
	import { goto } from '$app/navigation';
	import OrgLanding from '$lib/landing/OrgLanding.svelte';
	import { onMount } from 'svelte';
	import { FIXEE_TOKEN_NAME } from '../../api/fixee-client';
	import routes from '../routes';

	onMount(() => {
		const hasFixeeJwt = localStorage.getItem(FIXEE_TOKEN_NAME);
		if (hasFixeeJwt) {
			console.log('When logged in (has a token), redirect to home');
			goto(routes.home);
		} else {
			goto(routes.login);
		}
	});
</script>

<!-- <OrgLanding langLocale={$locale} /> -->
<OrgLanding />
